/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

export interface awsmobile {
    aws_project_region: string;
    aws_cognito_identity_pool_id: string;
    aws_cognito_region: string;
    oauth: any;
    aws_mobile_analytics_app_id: string;
    aws_mobile_analytics_app_region: string;
}

const config: awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:b4ef39bc-6b6c-4d0c-a422-8ab25f904fa4",
    "aws_cognito_region": "ap-northeast-1",
    "oauth": {},
    "aws_mobile_analytics_app_id": "defea5af774e4743afb1ff76d8d393ab",
    "aws_mobile_analytics_app_region": "us-west-2"
};


export default config;
